<template>
  <SignInLayout :subTitle="this.$t('login.subtitle', { brandName })">
    <div style="display: flex; justify-content: center; align-items: center; min-height: 50vh">
      <v-progress-circular v-if="loading" color="primary" indeterminate size="60" width="3" />
    </div>
  </SignInLayout>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { AuthApi } from "@/api/auth";
import SignInLayout from "@/components/SignInLayout";
import { useAuthStore } from "@/pinia-store/auth";

export default {
  name: "signInView",
  components: {
    SignInLayout,
  },
  data() {
    return {
      brandName: process.env.VUE_APP_BRAND_NAME,
      loading: true,
      ssoToken: null,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["customToken", "role"]),
  },
  methods: {
    ...mapActions(useAuthStore, ["setCustomToken"]),
    ...mapActions(useAuthStore, ["signInWithCustomToken"]),
  },
  async mounted() {
    this.$userActivityTracker.destroy();

    this.ssoToken = this.$route.query.ssoToken;
    try {
      const { customToken } = await AuthApi.getSSOCustomToken(this.ssoToken);
      this.setCustomToken(customToken);
      await this.signInWithCustomToken();
      await this.$router.push(`/${this.role}`);
    } catch (e) {
      console.error(e);
    }
  },
  beforeDestroy() {
    this.$userActivityTracker.startTimer();
  },
};
</script>
